.orders-view {
  min-height: 100vh;
  padding-top: 110px;
  padding-bottom: 30px;
  #report_link {
    visibility: hidden;
  }
  .search-wrapper {
    width: 80%;
    margin: 0 auto 20px;
    @media (max-width: 768px) {
      width: 97%;
    }
    .search-header {
      display: flex;
      align-items: center;
      svg {
        margin-left: 5px;
      }
    }
    .row {
      align-items: flex-end;
      flex-wrap: wrap;
      overflow: hidden;
      transition: max-height 0.2s ease;
      height: auto;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .col {
        margin-top: 10px;
        margin-right: 10px;
        @media (max-width: 768px) {
         width: 100%;
        }
      }
      .filter {
        display: flex;
        flex-direction: column;
        padding-bottom: 3px;
        .search-input {
          height: 33px;
          padding: 5px;
          &:focus {
            outline: none;
          }
        }
        .selectable-dropown {
          height: 33px;
          padding: 5px;
          &:focus {
            outline: none;
          }
        }
        .dates-container {
          display: flex;
          align-items: center;
          @media (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start;
          }
          .separator {
            margin: 0 5px;
            text-align: center;
            @media (max-width: 480px) {
              display: none;
            }
          }
          .react-datepicker-wrapper {
            .react-datepicker__close-icon::after {
              background-color: $secondary;
            }
            @media (max-width: 480px) {
              margin-bottom: 5px;
            }
          }
        }
      }
      .button-search {
        padding: 3px;
        text-align: right;
      }
    }
  }
  .orders-table {
    width: 80%;
    border-collapse: collapse;
    border: none;
    margin-right: 10px;
    @media (max-width: 768px) {
      width: 97%;
      font-size: 14px;
      .small-screen-remove {
        display: none;
      }
    }
    thead {
      tr {
        display: flex;
        th {
          flex: 1;
          align-items: center;
          min-height: 60px;
          background-color: #f2f3f4;
          padding: 2px;
          word-break: break-all;
          letter-spacing: 1px;
          &:nth-child(even) {
            background-color: $lightGrey;
          }
          div {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        };
      }
    }
    tbody {
      tr {
        min-height: 40px;
        display: flex;
        td {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          word-break: break-all;
          padding: 2px;
          background-color: #f2f3f4;
          &:nth-child(even) {
            background-color: $lightGrey;;
          }
          &.product-name {
            text-transform: uppercase;
          }
        }
      }
    };
  }
}
.products-view {
  min-height: 100vh;
  padding-top: 140px;
  padding-bottom: 30px;
  .container {
    .row {
      flex-wrap: wrap;
      justify-content: flex-start;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .single_product {
        min-width: 25%;
        max-width: 25%;
        padding: 30px 30px 10px;
        background-color: #333333;
        color: white;
        border: 1px solid #c09a18;
        @media (max-width: 991px) {
          min-width: 50%;
        }
        @media (max-width: 768px) {
          min-width: 100%;
          border: none;
          border-bottom: 3px solid #c09a18;
        }
        .img-wrap {
          min-height: 270px;
          &:hover {
            img {
              transition: all .2s ease-in-out;
              transform: scale(1.05);
            }
          }
          img {
            display: block;
            max-height: 270px;
            margin: auto;
            transform: scale(1);
            transition: all .2s ease-in-out;
          }
        }
      
        h1 {
          margin: 25px 0 0;
          text-transform: uppercase;
          font-size: 25px;
          height: 61px;
          @media (max-width: 768px) {
           height: unset
          }
        }
        p {
          min-height: 80px;
          max-height: 80px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          border-bottom: 3px solid #c09a18;
          margin-top: 5px;
        }
        .options-container {
          margin-top: 5px;
          height: 25px;
          display: flex;
          justify-content: space-between;
          a:hover {
            color: #c09a18;
          }
        }
      }
      a {
        color: white;
        letter-spacing: 1px;
      }
    }
  }
}

.popup-view {
  position: fixed;
  top: 90px;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  z-index: 101;
  .popup-bckgr {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    width: 100%;
    height: 100%;
    background-color: #333333;
    opacity: 0.4;
  }
  .popup-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: scroll;
    .popup-container {
      width: 300px;
      height: fit-content;
      margin: 50px auto;
      padding: 20px;
      background-color: white;
      border-radius: 3px;
      box-shadow: 0px 0px 0px 7px rgba(0, 0, 0, .25);
      position: relative;
      z-index: 102;
      text-align: center;
      p {
        color: #333333;
        text-align: center;
        padding: 0 10px;
        font-weight: bold;
        letter-spacing: 1px;
        vertical-align: middle;
        margin-top: 30px;
      }
    }
  }
}
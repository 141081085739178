.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  width: 100%;
  height: 90px;
  background-color: white;
  box-shadow: 0 5px 5px -6px black;
  .container {
    padding: 15px;
    .row {
      width: 100%;
    }
    .nav-link {
      color: #00213b;
      letter-spacing: 1px;
      &.selected {
        color: #c09a18;
        font-weight: bold;
        &:hover {
          transform: scale(1);
        }
      }
      &:hover {
        transform: scale(1.05);
      }
      &.log-out-btn {
        border: none;
        background-color: transparent;
        text-align: left;
        padding: 0;
        font-size: 16px;
        img {
          width: 31px;
          height: 31px;
        }
      }
    }
    .header-left {
      img {
        width: 200px;
      }
    }
    .header-center {
      flex: 1;
      display: flex;
      justify-content: space-between;
      @media (max-width: 991px) {
        display: none;
      }
    }
    .header-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media (max-width: 991px) {
        display: none;
      }
      .nav-link {
        margin-left: 10px;
      }
      .lang-holder {
        max-width: 100px;
        position: relative;
        &.nav-link {
          &:hover {
            transform: scale(1);
          }
        }
        .drop-value {
          margin: 0;
          display: flex;
          align-items: center;
          background: #e5d6a2;
          color: #00213b;
          padding: 2px 10px;
          height: 30px;
          cursor: pointer;
          span {
            &:hover {
              transform: scale(1.05);
            }
          }
          .fa {
            margin-left: 5px;
          }
        }
        .lang-dropdown {
          position: absolute;
          left: 0;
          top: 105%;
          width: 100%;
          background: #e5d6a2;
          display: none;
          flex-direction: column;
          text-align: left;
          div {
            padding: 2px 10px;
            position: relative;
            color: #00213b;
            cursor: pointer;
            &:after {
              content: "";
              position: absolute;
              top: 100%;
              left: 50%;
              width: 100%;
              height: 1px;
              background: #fff;
              transform: translateX(-50%);
            }
            &:hover {
              transform: scale(1.05);
            }
          }
          &.show {
            display: flex;
          }
        }
      }
    }

    .header-right-mobile {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 102;
      display: none;
      @media (max-width: 991px) {
        display: flex;
      }
      .toggle-icon {
        color: #333333;
        font-size: 35px;
        position: absolute;
        top: 40px;
        right: 15px;
        transform: translateY(-50%);
        @media (min-width: 990px) {
          display: none !important;
        }
        &.hamburger {
          display: none;
          width: 35px;
          height: 35px;
          span {
            display: block;
            background: #333333;
            height: 4px;
            width: 100%;
            &:after,
            &:before {
              content: "";
              position: absolute;
              left: 0;
              height: 4px;
              width: 100%;
              background: #333333;
            }
            &:after {
              top: calc(50% + 8px);
            }
            &:before {
              bottom: calc(50% + 8px);
            }
          }
          &.show {
            display: flex;
            align-items: center;
          }
        }
        &.close {
          display: none;
          #mdiv {
            width: 35px;
            height: 35px;
            padding-left: 4px;
          }
    
          .mdiv {
            height: 35px;
            width: 4px;
            margin-left: 12px;
            background-color: #333333;
            transform: rotate(45deg);
          }
    
          .md {
            height: 35px;
            width: 4px;
            background-color: #333333;
            transform: rotate(90deg);
          }
          &.show {
            display: block;
          }
        }
      }
    }
    .mobile-menu {
      display: none;
      @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        padding: 100px 0 20px 15px;
        top: 90px;
        left: 0;
        z-index: 102;
        width: 100%;
        background: #fff;
        box-shadow: 0 5px 5px -6px black;
        transform: translateX(-105%);
        transition: transform 0.4s;
        overflow-y: auto;
        &.show {
          transform: translateX(0);
        }
      }
      .nav-link {
       margin-bottom: 10px;
       &:hover {
         transform: scale(1);
       }
      }
      .lang-holder-mob {
        display: flex;
        @media (min-width: 990px) {
          display: none;
        }
        div {
          flex: 0;
          &.selected-lang {
            color: #c09a18;
          }
        }
        span {
          margin: 0 10px;
        }
      }
    }
  }
  
}
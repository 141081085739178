.order-details-view {
  position: fixed;
  top: 90px;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  .order-details-backgruond {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: #333333;
    opacity: 0.4;
  }
  .order-details-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: scroll;
    padding-bottom: 100px;
    .order-details-content {
      width: 80%;
      max-width: 1200px;
      min-height: 500px;
      margin: 50px auto;
      padding: 50px 30px;
      border-radius: 3px;
      background-color: white;
      box-shadow: 0px 0px 0px 7px rgba(0, 0, 0, .25);
      position: relative;
      z-index: 11;
      @media (max-width: 991px) {
        width: 90%;
      }
      .order-details-close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        &:hover {
          transform: scale(1.05);
        }
        .leftright, .rightleft {
          height: 4px;
          width: 30px;
          position: absolute;
          margin-top: 10px;
          background-color: #333333;
          border-radius: 2px;
        }
        .rightleft {
          transform: rotate(-45deg);
        }
        .leftright {
          transform: rotate(45deg);
        }
      }
      .order-details-container {
        margin: 20px 0;
        .row {
          .order-table {
            @media (max-width: 767px) {
              font-size: 12px;
            }
            .order-table-header  {
              display: flex;
              border-bottom: 2px solid #333333;
              div {
                flex: 1;
              }
            }
            .order-table-body {
              border-bottom: 2px solid #333333;
              .order-table-row {
                display: flex;
                margin: 10px 0;
                div {
                  flex: 1;
                }
              }
            }
            .order-header-cell, .order-body-cell {
              text-align: center;
              margin: 0 5px;
              word-break: break-word;
              &.small-cell {
                max-width: 50px;
                text-align: center;
                @media (max-width: 767px) {
                  display: none;
                }
              }
              &.big-cell {
                min-width: 50%;
                text-align: left;
                @media (max-width: 991px) {
                  min-width: 250px;
                }
                @media (max-width: 767px) {
                  min-width: 100px;
                }
                p {
                  text-align: left;
                  margin: 0;
                }
              }
              span {
                font-weight: bold;
              }
            }
            .order-table-total {
              margin-top: 10px;
              text-align: right;
              font-weight: bold;
            }
          }
      
        }
      }
      .order-details-ok {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        text-align: right;
        padding-right: 20px;
      }
    }
  }

}
.salgina {
  position: relative;
  padding-top: 140px;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      text-align: center;
      font-size: 28px;
      margin-top: 0;
      span {
        text-transform: uppercase;
      }
    }
    hr {
      width: 100%;
      height: 3px;
      margin: 0 0 15px 0;
      background-color: #c09a18;
      border: none;
    }
    .input-wrap {
      margin-top: 5px;
      display: flex;
      flex-wrap: wrap;
      &.inrows {
        flex-direction: column;
        margin-top: 20px;
      }
      label {
        margin-bottom: 2px;
        @media (max-width: 768px) {
           flex: 1;
        }
        button {
          color: blue;
          border: none;
          background-color: transparent;
          font-size: 16px;
          border-right: 2px solid #333333;
          max-width: 110px;
          word-break: break-all;
          padding: 0 5px 0 0;
        }
        span {
          margin: 0 15px 0 5px;
          padding-left: 5px;
          &.part-name {
            display: inline-block;
            padding-left: 0;
          }
          &.Tamina, &.Cascella {
            display: block;
            margin-left: 0;
          }
        }
      }
      .input-num {
        max-width: 100px;
        height: 20px;
        border: 1px solid #93a0ab;
        border-radius: 4px;
        text-align: right;
        &.input-disabled {
          border: none;
          border-bottom: 1px solid #333333;
          text-align: center;
          border-radius: 0;
        }
        @media (max-width: 991px) { 
          margin-bottom: 10px;
        }
      }
    }
    .salg-row {
      width: 90%;
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
      }
      .col {
        flex: 1;
        display: flex;
        flex-direction: column;
        &:nth-child(1) {
          padding-right: 30px;
          @media (max-width: 768px) {
            padding-right: 0;
          }
        }
        &:nth-child(3) {
          padding-left: 30px;
          @media (max-width: 768px) {
            padding-left: 0;
          }
        }
        &.fullwidth {
          flex: 0 0 100%;
          padding-right: 0;
          display: flex;
          .input-wrap {
            width: 50%;
            @media (max-width: 768px) {
              width: 100%;
            }
            label {
              flex: 1;
            }
            .input-num {
              margin-right: 10px;
              @media (max-width: 768px) {
                margin-right: 0;
              }
            }
          }
        }
        &.trift-cortex {
          padding-right: 0;
          .row {
            justify-content: flex-start;
          }
        }
        &.Dalvazza {
          max-width: 150px;
          hr {
            height: 4px;
          }
          @media (max-width: 768px) {
            max-width: unset;
          }
          .row {
            height: 100%;
          }
        }
        &.product {
          height: 100%;
          padding: 0;
          @media (max-width: 768px) {
            align-self: stretch;
            height: unset;
          }
        }
        @media (max-width: 768px) {
          flex: 1;
        }
        h2 {
          .poya-title {
            display: inline-block;
            width: 50%;
          }
        }
        .image-warp {
          padding: 10px 0;
          flex: 1;
          text-align: center;
          .Salgina-img {
            width: 100%;
            max-width: 100px;
          }
          .Dalvazza-img {
            width: 100%;
            max-width: 80px;
            @media (max-width: 991px) {
              max-width: 60px;
            }
            @media (max-width: 768px) {
              max-width: 100px;
            }
          }
          .Cascella-img {
            width: 100%;
            max-width: 100px;
          }
          .Tamina-img {
            width: 100%;
            max-width: 150px;
          }
          .Poya-img {
            width: 100%;
            max-width: 120px;
          }
          .Leporello-img {
            width: 100%;
            max-width: 120px;
          }
          &.schrauben {
            max-height: 110px;
            padding: 5px 0;
            text-align: left;
            margin-top: -15px;
            img {
              max-height: 100px;
              margin-left: 0;
            }
          }
        }
      }
      .special-details-container {
        width: 100%;
        background-color: $secondary;
        padding: 20px;
        p {
          margin: 0 0 20px;
        }
        .storage-inputs {
          display: flex;
          @media (max-width: 991px) { 
            flex-direction: column;
          }
          .input-wrap {
            flex: 1;
          
            @media (max-width: 991px) { 
              margin: 5px 0;
            }
            &:nth-child(2) {
              margin-left: 20px;
              @media (max-width: 991px) { 
                margin-left: 0;
              }
            }
            label {
              flex: unset;
              margin-right: 10px;
            }
            input {
              width: 100px;
            }
          }
        }
      }
    }
    .trift {
      width: 90%;
      padding-bottom: 30px;
      .trift-row {
        display: flex;
        align-items: center;
        .image-warp {
          flex: 1;
          height: 7vw;
          display: flex;
          align-items: center;
          img {
            height: 120%;
          }
        }
        .input-wrap {
          @media (max-width: 768px) {
            flex-direction: column;
            margin-top: 20px;
            label {
              display: flex;
              flex-direction: column;
              span {
                margin: 0;
              }
            }
          }
        }
      }
    }
    .order-btns-wrap {
      width: 80%;
      text-align: right;
      margin: 50px 0;
      @media (max-width: 768px) {
        width: 100%;
        text-align: center;
        margin: 30px 0;
      }
    }
  }
}
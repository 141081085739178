.article-view {
  position: fixed;
  top: 90px;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .article-backgruond {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: #333333;
    opacity: 0.4;
  }
  .article-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: scroll;
    padding-bottom: 100px;
    .article-content {
      width: 50%;
      min-height: 500px;
      margin: 50px auto;
      padding: 50px 30px;
      border-radius: 3px;
      background-color: white;
      box-shadow: 0px 0px 0px 7px rgba(0, 0, 0, .25);
      position: relative;
      z-index: 11;
      @media (max-width: 991px) {
        width: 80%;
        padding: 50px 20px;
      }
      .article-close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        &:hover {
          transform: scale(1.05);
        }
        .leftright, .rightleft {
          height: 4px;
          width: 30px;
          position: absolute;
          margin-top: 10px;
          background-color: #333333;
          border-radius: 2px;
        }
        .rightleft {
          transform: rotate(-45deg);
        }
        .leftright {
          transform: rotate(45deg);
        }
      }
      .article-container {
        .row {
          align-items: flex-start;
          @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
          }
          .article-img {
            flex: 2;
            border-radius: 3px;
            margin-right: 30px;
            text-align: center;
            padding: 10px;
            position: relative;
            img {
              max-height: 400px;
              width: auto;
              max-width: 250px;
              @media (max-width: 991px) {
                max-height: 330px;
              }
              @media (max-width: 768px) {
                max-height: 300px;
              }
              @media (max-width: 480px) {
                max-height: 250px;
              }
            }
            &.rotate {
              min-width: 200px;
              position: relative;
              @media (max-width: 768px) {
                min-height: 300px;
              }
              img {
                max-width: 300px;
                transform: rotate(-90deg);
                height: auto;
                position: absolute;
                top: 70px;
                left: 0;
                @media (max-width: 768px) {
                  left: -20%;
                }
              }
            }
          }
          .article-info {
            flex: 3;
            @media (max-width: 768px) {
              width: 100%;
            }
            span {
              color: blue;
            }
            hr {
              height: 3px;
              background-color: #c09a18;
              border: none;
            }
            h3, h4 {
              margin-bottom: 0;
            }
            h4 {
              margin-top: 5px;
            }
            .article-description {
              border: 1px solid #333333;
              min-height: 100px;
              padding: 5px;
            }
            .article-price {
              font-weight: bold;
              text-align: right;
            }
          }
        }
    
      }
      .article-ok {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        text-align: right;
        padding-right: 20px;
      }
    }
  }

}
* {
  box-sizing: border-box;
}

@font-face {
  font-family: frutiger;
  src: url("./../assets/fonts/FrutigerLTPro-Light.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: frutigerBold;
  src: url("./../assets/fonts/FrutigerLTPro-Light.otf");
  font-weight: bold;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "frutiger", "sans-serif";
  overflow-x: hidden;
  &::-webkit-scrollbar { 
    display: none; 
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
  &:focus {
    outline: none;
  }
 
  &.btn-style {
    background: none;
    color: #333333;
    font-weight: bold;
    border: 1px solid #333333;
    padding: 5px 20px;
    // border-radius: 30px;
    font-size: 18px;
    &.red {
      color: red;
      border: 1px solid red;
      margin: 0 10px;
    }
    &.green {
      color: green;
      border: 1px solid green;
    }
  }
}

.btn-as-link {
  border: none;
  background-color: transparent;
  color: blue;
}

.container {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .col {
    flex: 1;
  }
}

.icons {
  height: 35px;
  width: 35px;
}

.warning_msg {
  color: red;
  text-align: left;
  margin-top: 30px;
  display: flex;
  align-items: center;
  .error {
    transform: scale(0.7);
  }
}

.successfull, .error {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: green;
  font-size: 30px;
  line-height: 50px;
  color: white;
  text-align: center;
  &::before {
   content: "✓";
  }
}
.successfull {
  background-color: green;
  &::before {
    content: "✓";
  }
}
.error {
  background-color: red;
  font-weight: 600;
  &::before {
    content: "!";
  }
}

